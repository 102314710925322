.headerTextB {
  flex: 1;
  position: relative;
  display: inline-block;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #515255;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.headerCellDiv,
.contentHeaderCellDiv {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.contentHeaderCellDiv {
  background-color: #fff;
}
.headerCellDiv,
.contentHeaderCellDiv {
  width: 159px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 8px 10px;
  box-sizing: border-box;
  align-items: center;
}
.headerCellDiv {
  background-color: #dfdfdf;
}
.headerCellDiv1,
.tableHeaderDiv,
.contentCellDiv,
.contentCellDivCanceled,
.contentCellDivActive {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.contentCellDiv {
  background-color: #fff;
}
.contentCellDivCanceled {
  background-color: #f2dede;
}
.contentCellDivActive {
  background-color: #def2e0;
}
.headerCellDiv1,
.contentCellDiv,
.contentCellDivCanceled,
.contentCellDivActive {
  flex: 1;
  flex-direction: row;
  padding: 8px 10px;
  box-sizing: border-box;
  align-items: center;
}
.headerCellDiv1 {
  background-color: #dfdfdf;
}
.tableHeaderDiv {
  background-color: #a8a8aa;
}
.tableHeaderDiv {
  flex-direction: row;
  align-items: center;
  gap: 1px;
}
.textDiv {
  flex: 1;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #515255;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.rowHeader,
.rowsDiv1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.rowHeader {
  background-color: #a8a8aa;
  flex-direction: row;
  align-items: center;
  gap: 1px;
  text-align: left;
  font-size: 14px;
  font-family: Mulish;
}
.rowsDiv1 {
  flex-direction: column;
  align-items: flex-start;
}
.rowsDiv1 {
  background-color: #a8a8aa;
  border: 1px solid #a8a8aa;
  box-sizing: border-box;
  position: relative;
  gap: 1px;
}
