.fileSelectorDiv {
  display: flex;
  align-content: center;
  align-items: center;
}
.fileNameDiv {
  color: #4d4d4d;
  margin-left: 5px;
  display: flex;
  align-items: center;
  border-radius: 9999px;
  padding: 12px;
  width: 150px;
}
.fileNameDiv:hover {
  background-color: #f2dede;
}
.xButton:hover {
  background-color: #d6adad;
  border-radius: 9999px;
  padding: 2px;
}
.fileNameDiv > * + * {
  margin-left: 4px;
}
.xButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 18px;
  height: 18px;
}
.vectorIcon {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}
.vectorIcon:hover {
  stroke-width: 10;
}
.fileNameP {
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
