/* TODO: https://algorath.atlassian.net/browse/WMA-51  */

.userDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.userDiv {
  flex-direction: row;
  align-items: center;
}
.userDiv > * + * {
  margin-left: 16px;
}
.requestsListB {
  display: inline-block;
}
.buttonText,
.icon,
.requestsListB {
  position: relative;
}
.icon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  overflow: hidden;
}
.buttonText {
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  font-family: Mulish;
  color: #fffffe;
  text-align: left;
}
.button {
  cursor: pointer;
  border: 0;
  padding: 12px 24px;
  background-color: #973fd4;
  border-radius: 9999px;
  box-shadow: 0 5px 6px rgba(103, 52, 168, 0.3);
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.button > * + * {
  margin-left: 8px;
}
.button:hover {
  background-color: #7d39ae;
}
.upsideBarDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.groupIcon,
.searchText {
  position: relative;
  width: 18.16px;
  height: 18.16px;
}
.searchText {
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-family: Mulish;
  color: #898989;
  text-align: left;
  display: inline-block;
  width: 100%;
  height: 17px;
  resize: none;
  outline: none;
}
.frameDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  align-items: center;
}
.frameDiv > * + * {
  margin-left: 13px;
}
.searchBarDiv {
  border-radius: 32px;
  background-color: #fff;
  box-shadow: 0 5px 6px rgba(103, 52, 168, 0.3);
  display: flex;
  width: 30%;
  min-width: 200px;
  max-width: 232px;
  flex-direction: column;
  padding: 12px 16px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.bubbleSelectorContentText {
  position: relative;
  font-size: 14px;
  display: inline-block;
  font-family: Mulish;
  text-align: left;
}
.bubbleSelectorContentDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.bubbleSelectorContentDiv > * + * {
  margin-left: 6px;
}
.bubbleSelectorButton {
  cursor: pointer;
  border: 2px solid #973fd4;
  padding: 8px 16px;
  background-color: #fff;
  color: #973fd4;
  border-radius: 46px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bubbleSelectorButtonActive {
  background-color: #973fd4;
  color: #ffff;
}
.bubbleSelectorButton:hover {
  background-color: #faf4fe;
}
.bubbleSelectorButtonActive:hover {
  background-color: #7d39ae;
}
.navigateDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.navigateDiv > * + * {
  margin-top: 14px;
}
.contentDiv > * + * {
  margin-top: 37px;
}
.containerDiv {
  height: 100%;
  padding: 0 66px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  font-size: 34px;
  color: #000;
}
.statusResolved {
  font-weight: bold;
  color: #24a533;
}
.statusDeleted {
  font-weight: bold;
  color: #e55b57;
}
.statusRejected {
  font-weight: bold;
  color: #ff9533;
}
.statusSubmitted {
  font-weight: bold;
  color: #596573;
}
.statusCompleted {
  font-weight: bold;
  color: #497cff;
}
.requestID {
  font-weight: bold;
}

.test {
  background-color: #973fd4;
  color: #ffff;
}

@media only screen and (max-width: 750px) {
  .upsideBarDiv {
    flex-direction: column;
  }
  .button {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
  .searchBarDiv {
    width: 100%;
  }
  .userDiv {
    width: 100%;
    margin-top: 16px;
    justify-content: center;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.navigationDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #7c7d80;
  font-family: Mulish;
}
.navigationDiv > * + * {
  margin-left: 16px;
}
.xButton {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  border: 0;
  padding: 2px;
  background-color: transparent;
  justify-content: center;
  border-radius: 100%;
  width: 24px;
  height: 18px;
}
.xButton:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.224) 0 0);
}
.xIcon {
  position: relative;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  filter: brightness(0) saturate(100%) invert(67%) sepia(0%) saturate(292%)
    hue-rotate(307deg) brightness(109%) contrast(70%);
}
.xButton:hover .xIcon {
  filter: brightness(0) saturate(100%) invert(0%) sepia(8%) saturate(10%)
    hue-rotate(154deg) brightness(99%) contrast(101%);
}
.filterContainerDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 12px;
}
.filterButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: 0;
  padding: 8px 8px;
  background-color: transparent;
  border-radius: 999999px;
  box-sizing: border-box;
  justify-content: center;
}
.filterButton:hover {
  background-color: rgba(150, 62, 212, 0.12);
}
.contentDiv1,
.filterButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contentDiv1 {
  justify-content: flex-start;
  gap: 4px;
}
.filterIconDiv {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.filtersB {
  position: relative;
  font-size: 14px;
  display: inline-block;
  font-family: Mulish;
  color: #973fd4;
}
.clearAllDiv {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.clearAllButton,
.clearAllButton:hover {
  box-sizing: border-box;
  position: relative;
}
.clearAllButton {
  cursor: pointer;
  border: 1px solid transparent;
  padding: 6px;
  background-color: transparent;
  border-radius: 9999px;
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.clearAllButton:hover {
  background-color: rgba(132, 132, 132, 0.2);
  border: 1px solid rgba(112, 112, 112, 0.5);
}
.clearAllB {
  position: relative;
  font-size: 12px;
  display: inline-block;
  font-family: Mulish;
  color: #707070;
  text-align: left;
  white-space: nowrap;
}
.broomIcon {
  position: relative;
  width: 14.4px;
  height: 14.4px;
  flex-shrink: 0;
}
.filterIcon {
  position: relative;
  width: 18px;
  height: 12px;
  flex-shrink: 0;
}

.chipsContainer,
.chipsFrame,
.chipsRow {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chipsFrame {
  flex-direction: row;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
}
.chipsContainer,
.chipsRow {
  flex-direction: column;
}
.chipsRow {
  padding: 0 0 0 8px;
  box-sizing: border-box;
}
.chipsFrame > * {
  flex: 0 0 auto;
}
.chipsContainer {
  position: relative;
  width: 100%;
  gap: 6px;
  text-align: left;
  font-size: 12px;
  color: #7c7d80;
  font-family: Mulish;
}
.actionButtons {
  display: flex;
  flex-direction: row;
  gap: 14px;
}
