.tittle {
  position: relative;
  font-weight: 600;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #515255;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 24px;
  -webkit-text-fill-color: transparent;
}
.popupTittle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vectorIcon {
  position: relative;
  width: 24px;
  height: 24px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.x,
.x:hover {
  width: 24px;
  height: 24px;
}
.x {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #fff;
  position: relative;
  border-radius: 100000000376832px;
  flex-shrink: 0;
  overflow: hidden;
}
.x:hover {
  background-color: rgba(151, 63, 212, 0.15);
}
.content {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.divider {
  align-self: stretch;
  position: relative;
  border-top: 1.5px solid #d3d4d5;
  box-sizing: border-box;
  height: 1.5px;
  flex-shrink: 0;
}
.header {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  text-align: center;
  font-size: 20px;
}
.xSmallIcon {
  position: relative;
  width: 14px;
  height: 14px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.xSmall {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  border-radius: 110px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
}
.xSmall:hover {
  background-color: rgba(150, 63, 212, 0.16);
}
.emailAddress {
  position: relative;
  font-weight: 500;
  font-size: 14px;
}
.email {
  border-radius: 179px;
  background-color: #f3f5fa;
  border: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  padding: 6px 12px;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  flex: 0 0 auto;
}
.emailContent,
.email-wrapper,
.frameWrapper {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.emailContent {
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}
.email-wrapper,
.frameWrapper {
  flex-direction: column;
  justify-content: center;
}
.email-wrapper {
  flex-direction: row;
  justify-content: flex-start;
}
.emailInput {
  flex: 1;
  display: flex;
  align-items: center;
}
.emailInput {
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid #a8a8aa;
  box-sizing: border-box;
  height: 38px;
  flex-direction: row;
  padding: 8px;
  font-size: 14px;
  width: 100%;
  justify-content: flex-start;
}
.add,
.addIcon {
  position: relative;
}
.addIcon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
  filter: brightness(0) saturate(100%) invert(33%) sepia(50%) saturate(2757%)
    hue-rotate(256deg) brightness(84%) contrast(97%);
}
.add {
  font-size: 14px;
  font-family: Mulish;
  text-align: right;
}
.addButton,
.addButtonContent {
  flex-direction: row;
  align-items: center;
}
.addButtonContent {
  display: flex;
  justify-content: flex-start;
  gap: 6px;
}
.addButton {
  cursor: pointer;
  border: 1.8px solid #973fd4;
  padding: 8px;
  background-color: #fff;
  border-radius: 4px;
  color: #973fd4;
  justify-content: center;
  height: 38px;
}
.addButton:hover {
  background-color: rgba(125, 57, 174, 0.17);
}
.addButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.addButton,
.group,
.headerParent {
  align-self: stretch;
  display: flex;
}
.group {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  font-size: 14px;
  color: #707070;
}
.headerParent {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.saveButtonText {
  position: relative;
  font-size: 16px;
  font-family: Mulish;
  color: #fff;
  text-align: right;
}
.saveButton {
  cursor: pointer;
  border: 0;
  padding: 8px;
  background-color: #973fd4;
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.saveButton:hover {
  background-color: #7d39ae;
}
.cancelButtonText {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-family: Mulish;
  color: #7c7d80;
  text-align: left;
}
.arrow-right-icon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.cancelButton,
.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cancelButton {
  cursor: pointer;
  border: 0;
  padding: 8px 24px;
  background-color: transparent;
  border-radius: 9999px;
  justify-content: center;
  gap: 8px;
}
.cancelButton:hover {
  background-color: rgba(224, 224, 224, 0.5);
  border-radius: 24px;
}
.buttonsContainer {
  justify-content: flex-start;
  gap: 16px;
}
.container,
.frame {
  display: flex;
  flex-direction: column;
}
.frame {
  align-self: stretch;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
}
.container {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 4px 10px 79px rgba(85, 37, 148, 0.39);
  width: 100%;
  overflow: hidden;
  padding: 24px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: 12px;
  color: #000;
  font-family: Mulish;
}

.chipsContainer,
.chipsFrame,
.chipsRow {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chipsFrame {
  flex-direction: row;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
}
.chipsContainer,
.chipsRow {
  flex-direction: column;
}
.chipsRow {
  padding: 0 0 0 8px;
  box-sizing: border-box;
}
.chipsFrame > * {
  flex: 0 0 auto;
}
.chipsContainer {
  position: relative;
  width: 100%;
  gap: 6px;
  text-align: left;
  font-size: 12px;
  color: #7c7d80;
  font-family: Mulish;
}
