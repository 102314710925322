/* TODO: https://algorath.atlassian.net/browse/WMA-51  */

.logoIcon {
  position: relative;
  width: 146.3px;
  height: 47.5px;
  flex-shrink: 0;
}
.appName {
  position: relative;
  text-transform: capitalize;
  display: inline-block;
}
.poweredByDiv {
  position: relative;
  font-weight: 500;
  display: inline-block;
}
.frameIcon {
  position: relative;
  width: 73px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}
.logoAlgorathDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.logoAlgorathDiv > * + * {
  margin-left: 4px;
}
.logoAlgorathDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 12px;
  color: #515151;
}
.userLogoIcon {
  max-width: 100%;
  max-height: 100%;
}
.description,
.identificationP {
  margin: 0;
  position: relative;
  display: inline-block;
}
.description {
  align-self: stretch;
  font-weight: bold;
  color: #e712b1;
}
.userDiv,
.userInformationDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.userDiv {
  flex-direction: row;
  align-items: center;
}
.userDiv > * + * {
  margin-left: 16px;
}
.homeIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.fullLogDiv,
.logoButton {
  display: flex;
  flex-direction: row;
}
.logoButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-items: flex-start;
  justify-content: flex-start;
}
.fullLogDiv {
  align-items: center;
  justify-content: center;
  color: #232323;
}
.fullLogDiv > * + * {
  margin-left: 42px;
}
.header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 50px 70px 42px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 16px;
  color: #545256;
  font-family: Mulish;
}
.appIcon {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  filter: brightness(0) saturate(100%) invert(32%) sepia(23%) saturate(6388%)
    hue-rotate(258deg) brightness(86%) contrast(92%);
}
.logoAlgorathAppDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  color: #6c6c6c;
}
.appLogoDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-width: 250px;
  font-size: 16px;
  height: 62.5px;
  color: #973fd4;
  font-family: Mulish;
}
.appFrameIcon {
  position: relative;
  width: 64px;
  height: 14px;
  flex-shrink: 0;
  overflow: hidden;
}
.appNameTittleDiv {
  align-self: stretch;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.appNameTittleDiv > * + * {
  margin-left: 8px;
}
