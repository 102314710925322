.content {
  margin-top: 100px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  width: 50%;
  margin-top: 100px;
}
