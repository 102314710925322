.purpleText {
  margin: 0;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  font-family: Mulish;
  color: #8320ca;
  text-align: left;
  display: inline-block;
}
.iconsDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.iconsDiv > * + * {
  margin-left: 8px;
}
.bodyCellButton {
  cursor: pointer;
  border: 0;
  padding: 12px 20px;
  background-color: transparent;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.bodyCellButton:hover .purpleText {
  color: #5b0c93;
}
.bodyCellButton:hover .addIcon {
  filter: brightness(0) saturate(100%) invert(11%) sepia(100%) saturate(5242%)
    hue-rotate(276deg) brightness(63%) contrast(101%);
}
.footer {
  align-self: stretch;
  flex-shrink: 0;
  border-radius: 0 0 8px 8px;
  background-color: #dfdfdf;
  height: 52px;
  display: flex;
  align-items: center;
}
