.containerDiv {
  display: flex;
  justify-content: right;
  padding: 0 66px;
}
.guideButton,
.questionButton {
  border: none;
  cursor: pointer;
  border-radius: 999px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.guideButton:hover,
.questionButton:hover {
  background-color: rgba(132, 132, 132, 0.2);
  border: 1px solid rgba(112, 112, 112, 0.5);
}
.personIconDiv {
  height: 61.07%;
  width: 61.07%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.addButton {
  border-radius: 999999px;
  background-color: #5391cb;
  width: 38px;
  height: 38px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addButton:hover {
  background-color: #4180ba;
}
.addButtonParent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  height: 38px;
}
