.headerCellDiv {
  width: 159px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 8px 10px;
  box-sizing: border-box;
  align-items: center;
  background-color: #dfdfdf;
}
.arrowIcon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}
.arrowIconDiv {
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.statusDiv {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.rowsDiv,
.tableHeaderDiv,
.contentCellDiv {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.contentCellDiv {
  background-color: #fff;
}
.contentCellDiv {
  flex: 1;
  flex-direction: row;
  padding: 8px 10px;
  box-sizing: border-box;
  align-items: center;
}
.rowsDiv,
.tableHeaderDiv {
  background-color: #a8a8aa;
}
.tableHeaderDiv {
  flex-direction: row;
  align-items: center;
  gap: 1px;
}
.rowsDiv {
  border: 1px solid #a8a8aa;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}
.entryDiv {
  align-self: stretch;
  padding: 16px 0;
  align-items: center;
  gap: 24px;
  min-width: 50vw;
  max-width: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: flex-start;
}
.divider,
.dividerContainer {
  align-self: stretch;
  box-sizing: border-box;
}
.divider {
  position: relative;
  border-top: 2px solid #b1b1b1;
  height: 1px;
  flex-shrink: 0;
}
.dividerContainer {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  align-items: flex-start;
  justify-content: flex-start;
}
.contentDiv {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.statusResolved {
  font-weight: bold;
  color: #24a533;
}
.statusDeleted {
  font-weight: bold;
  color: #e55b57;
}
.statusRejected {
  font-weight: bold;
  color: #ff9533;
}
.statusSubmitted {
  font-weight: bold;
  color: #596573;
}
.statusCompleted {
  font-weight: bold;
  color: #497cff;
}
.infoTextB {
  position: relative;
  display: inline-block;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #515255;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.infoDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.infoDiv1 {
  flex-direction: row;
  align-items: center;
}
.headerCellDiv,
.infoDiv1,
.infoContainerDiv {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.infoContainerDiv {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.headerTextB {
  flex: 1;
  position: relative;
  display: inline-block;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #515255;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
