.headerCellFixedDiv {
  width: 56px;
  flex-shrink: 0;
}
.textDiv {
  flex: 1;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.headerCellFixedDiv1 {
  height: 52px;
  display: flex;
  flex-direction: row;
  padding: 12px 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  width: 92px;
  flex-shrink: 0;
}
.indexHeader {
  height: 52px;
  align-self: stretch;
  border-radius: 8px 8px 0 0;
  background-color: #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #252525;
  font-family: Mulish;
  z-index: 900;
  box-shadow: 5px 0 5px 0px #d5d5d5;
  border-radius: 8px 0px 0 0;
}
.indexTextDiv {
  flex: 1;
  position: relative;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.undoIcon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
  filter: brightness(0) saturate(100%) invert(32%) sepia(22%) saturate(706%)
    hue-rotate(314deg) brightness(104%) contrast(81%);
}
.deleteIcon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}
.bodyCellFixedButton {
  cursor: pointer;
  border: 0;
  border-radius: 100%;
  padding: 8px 20px;
  background-color: transparent;
  align-self: stretch;
  width: 56px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.addDiv:hover,
.bodyCellFixedButton:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.115) 0 0);
}
.bodyCellFixedDiv {
  align-self: stretch;
  background-color: transparent;
  width: 92px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 12px 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.textP {
  margin: 0;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.indexCellFixedDiv {
  align-self: stretch;
  width: 92px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 12px 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.indexCellFixedDiv {
  justify-content: center;
}
.bodyCellTextField {
  border: 0;
  background-color: transparent;
}
.rowsDiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 1800px;
  flex-direction: column;
}
.rowsBoxDiv {
  align-self: stretch;
  position: relative;
  background-color: #fafafa;
  height: calc(100% - 52px);
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.containerDiv {
  min-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.tableHeadDiv {
  align-self: stretch;
  max-height: 40vh;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.footerContainerDiv,
.newRequestTable {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.footerContainerDiv {
  align-self: stretch;
}
.newRequestTable {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 5px 6px rgb(103 52 168 / 30%);
  width: 100%;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  color: #737373;
  font-family: Mulish;
}
.indexContainerDiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.indexTableHeadDiv {
  background-color: #fff;
  align-self: stretch;
  max-height: 50vh;
}
.indexRowsDiv {
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
}
.indexRowDiv,
.indexRowsDiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.indexRowDiv + .indexRowDiv {
  border-top: 0;
}
.indexRowDiv {
  border-color: #e6e6e6 !important;
  border-top: solid;
  border-bottom: solid;
  height: 56px;
  align-self: stretch;
  flex-direction: row;
}
.indexRowsBoxDiv {
  z-index: 900;
  box-shadow: 5px 0 5px 0px #e6e6e6;
  align-self: stretch;
  position: relative;
  background-color: #fafafa;
  height: calc(100% - 62px);
  flex-shrink: 0;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.indexDivider {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.indexRowsBoxDiv::-webkit-scrollbar {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
