.icon {
  position: relative;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  overflow: hidden;
}
.inactiveTeamB {
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  font-family: Mulish;
  color: #fffffe;
  text-align: left;
}
.contentDiv {
  align-items: left;
  display: flex;
  position: relative;
  gap: 8px;
}
.buttonShrinkPrimary,
.buttonShrinkTerciary,
.buttonShrinkRed {
  cursor: pointer;
  border: 0;
  padding: 12px 21px 12px 21px;
  position: relative;
  border-radius: 9999px;
  box-shadow: 0 5px 6px rgba(103, 52, 168, 0.3);
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.buttonShrinkPrimary {
  background-color: #973fd4;
}
.buttonShrinkPrimary:hover {
  background-color: #7d39ae;
}
.buttonShrinkTerciary {
  background-color: #53cbc6;
}
.buttonShrinkTerciary:hover {
  background-color: #51a9a6;
}
.buttonShrinkRed {
  background-color: #fb4e4e;
}
.buttonShrinkRed:hover {
  background-color: #d93b3b;
}
