.button {
  height: 100%;
  border: none;
  cursor: pointer;
  padding: 8px;
  background-color: transparent;
}
.button:hover {
  background-color: rgba(82, 82, 82, 0.2);
}
.text {
  color: #fff;
  font-size: 14px;
}
.variantError {
  max-width: 80% !important;
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap !important;
}
