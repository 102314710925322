.progressDiv {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  z-index: 1;
  justify-content: center;
  align-items: center;
}
