.tittleB {
  position: relative;
  display: inline-block;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #515255;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.popuptittleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.vectorIcon {
  position: absolute;
  height: 58.33%;
  width: 58.33%;
  top: 20.83%;
  right: 20.83%;
  bottom: 20.83%;
  left: 20.83%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.xButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #fff;
  position: relative;
  border-radius: 100000000376832px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.xButton:hover {
  background-color: rgba(151, 63, 212, 0.15);
  width: 24px;
  height: 24px;
}
.contentDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.bigdivider {
  align-self: stretch;
  position: relative;
  border-top: 1.5px solid #d3d4d5;
  box-sizing: border-box;
  height: 1.5px;
  flex-shrink: 0;
}
.headerDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.statusB {
  position: relative;
  display: inline-block;
  width: 100%;
}
.statusLabel {
  width: 45px;
}
.boxDiv {
  position: relative;
  border-radius: 2px;
  border: 1px solid #737373;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
.statustextB {
  flex: 1;
  position: relative;
  display: flex;
  font-size: 12px;
}
.checkfilterDiv,
.component42Div,
.rowchecksDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.rowchecksDiv + .rowchecksDiv {
  margin-top: 8px;
}
.checkfilterDiv {
  width: 90px;
  flex-shrink: 0;
  align-items: center;
  gap: 8px;
}
.component42Div,
.rowchecksDiv {
  align-items: flex-start;
}
.rowchecksDiv {
  align-items: center;
  gap: 32px;
}
.checkcontainerIcon {
  position: relative;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  overflow: hidden;
}
.component38Div2 {
  border-radius: 2px;
  background-color: #973fd4;
  display: flex;
  flex-direction: row;
  padding: 3px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.containerDiv1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.divider,
.statusDiv {
  box-sizing: border-box;
}
.statusDiv {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 8px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 27px;
  width: 100%;
}
.divider {
  align-self: stretch;
  position: relative;
  border-top: 1px solid #d3d4d5;
  height: 1px;
  flex-shrink: 0;
}
.inputText {
  border: none;
  resize: none;
  outline: none;
  width: 100%;
}
.inputDate {
  border: none;
  resize: none;
  outline: none;
  width: 80px;
}
.calendarIconDiv {
  padding-right: 12px;
}
.clearButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  justify-content: center;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border: 0;
  padding: 0;
}
.clearButton:hover {
  background-color: rgba(151, 63, 212, 0.8);
}
.xIcon {
  position: relative;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  filter: brightness(0) saturate(100%) invert(67%) sepia(0%) saturate(292%)
    hue-rotate(307deg) brightness(109%) contrast(70%);
}
.clearButton:hover .xIcon {
  filter: brightness(0) saturate(100%) invert(0%) sepia(8%) saturate(10%)
    hue-rotate(154deg) brightness(99%) contrast(101%);
}
.buttonclose {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #b8b8b8;
  position: relative;
  border-radius: 99999px;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  overflow: hidden;
}
.buttonclose:hover {
  background-color: rgba(151, 63, 212, 0.8);
}
.inputDiv,
.inputDiv1,
.inputcontainerDiv {
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  width: 100%;
}
.inputDiv1 {
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #7c7d80;
  position: relative;
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
}
.inputDiv1:hover,
.inputDiv5:hover {
  border: 1px solid #973fd4;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 0 2px rgba(151, 63, 212, 0.25);
}
.inputDiv,
.inputcontainerDiv {
  flex-direction: column;
  align-items: flex-start;
}
.inputcontainerDiv {
  padding: 2px;
  font-family: Mulish;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  align-items: center;
}
.inputDiv {
  padding: 2px 2px 2px 8px;
  gap: 8px;
}
.dateselectedDiv,
.textDiv {
  position: relative;
  display: inline-block;
}
.textDiv {
  font-weight: 500;
}
.dateselectedDiv {
  flex: 1;
  align-self: stretch;
  line-height: 22px;
}
.calendarIcon,
.inputDiv6 {
  flex-shrink: 0;
  overflow: hidden;
}
.inputDiv6 {
  height: 24px;
  display: flex;
  flex-direction: row;
  padding: 1px 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.calendarIcon {
  position: relative;
  width: 14px;
  height: 14px;
}
.datecontentDiv,
.suffixDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.suffixDiv {
  height: 24px;
  padding: 0 4px;
  box-sizing: border-box;
}
.datecontentDiv {
  width: 100%;
  flex-shrink: 0;
  gap: 4px;
}
.component45Div,
.inputDiv5 {
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
}
.inputDiv5 {
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #7c7d80;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  padding: 4px 8px;
  align-items: center;
}
.component45Div {
  flex-direction: column;
  padding: 2px;
  align-items: flex-start;
  color: #7c7d80;
  font-family: Mulish;
}
.daterangeDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7px;
  color: #a8a8aa;
}
.inputDiv4 {
  display: flex;
  flex-direction: column;
  padding: 2px 0 2px 8px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.divider4 {
  align-self: stretch;
  position: relative;
  border-top: 1px solid #d3d4d5;
  box-sizing: border-box;
  height: 1px;
  flex-shrink: 0;
  display: none;
}
.containerDiv,
.frameDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frameDiv {
  justify-content: center;
  width: 412px;
  gap: 16px;
}
.containerDiv {
  justify-content: flex-start;
  text-align: left;
  font-size: 12px;
}
.labelbuttontextDiv {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  font-family: Mulish;
  color: #973fd4;
  text-align: right;
  display: inline-block;
}
.buttontext {
  cursor: pointer;
  border: 0;
  padding: 8px;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.buttontext:hover {
  background-color: rgba(151, 63, 212, 0.15);
}
.labelbuttonfilledDiv {
  position: relative;
  font-size: 12px;
  font-weight: 600;
  font-family: Mulish;
  color: #fff;
  text-align: right;
  display: inline-block;
}
.buttonfilled,
.buttonscontainerDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttonfilled {
  cursor: pointer;
  border: 0;
  padding: 8px;
  background-color: #973fd4;
  border-radius: 4px;
  box-sizing: border-box;
  justify-content: center;
}
.buttonfilled:hover {
  background-color: #7d39ae;
}
.buttonscontainerDiv {
  align-self: stretch;
  justify-content: flex-end;
  gap: 24px;
}
.filtersDiv {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 4px 28px 79px rgba(85, 37, 148, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 21px;
  text-align: center;
  font-size: 16px;
  color: #7c7d80;
  font-family: Mulish;
}
