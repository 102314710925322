.recipients {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
  text-align: left;
  font-size: 16px;
  color: #4e4e4e;
  font-family: Mulish;
  height: 707px;
}
.recipientsTitleText {
  position: relative;
}
.recipientsTitle {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  align-items: center;
  justify-content: flex-start;
  color: #4e4e4e;
}
.searchIcon,
.text2 {
  position: relative;
}
.searchIcon {
  width: 18.16px;
  height: 18.16px;
  flex-shrink: 0;
}
.text2 {
  flex: 1;
  border: none;
  resize: none;
  outline: none;
}
.content,
.textContainer {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content {
  height: 18.16px;
  align-items: flex-end;
  gap: 13px;
}
.cancelIcon {
  position: relative;
  flex-shrink: 0;
  filter: brightness(0) saturate(100%) invert(67%) sepia(0%) saturate(292%)
    hue-rotate(307deg) brightness(109%) contrast(70%);
}
.searchBar,
.x {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.x {
  cursor: pointer;
  border: 0;
  padding: 2px;
  background-color: transparent;
  justify-content: center;
}
.searchBar {
  align-self: stretch;
  justify-content: flex-start;
  gap: 10px;
}
.line,
.searchBarContainer {
  align-self: stretch;
  flex-shrink: 0;
}
.line {
  position: relative;
  border-top: 1px solid #a8a8aa;
  box-sizing: border-box;
  height: 1px;
}
.searchBarContainer {
  width: 344px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}
.text3 {
  position: relative;
}
.text3 {
  font-size: 16px;
  line-height: 20px;
  font-family: Mulish;
  color: #fffffe;
  text-align: left;
}
.buttonAdd,
.navigate {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttonAdd {
  cursor: pointer;
  border: 0;
  padding: 12px 24px 12px 21px;
  background-color: #5391cb;
  border-radius: 9999px;
  box-shadow: 0 5px 6px rgba(103, 52, 168, 0.3);
  height: 42px;
  box-sizing: border-box;
  justify-content: flex-start;
  gap: 8px;
}
.buttonAdd:hover {
  background-color: #1f5e97;
}
.navigate {
  align-self: stretch;
  padding: 12px 16px;
  justify-content: space-between;
}
.tableNameText {
  position: relative;
}
.tableHeaderText {
  position: relative;
  font-weight: 600;
}
.tableText {
  position: relative;
}
.categoryEmail,
.tableHeader,
.tableName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tableName {
  align-self: stretch;
  padding: 0 0 0 8px;
}
.categoryEmail,
.tableHeader {
  padding: 0 0 0 32px;
}
.tableHeader {
  align-self: stretch;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
  height: 54px;
  flex-shrink: 0;
  overflow: hidden;
  padding: 12px 16px 12px 12px;
}
.userIcon {
  position: relative;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}
.identification {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.deleteIcon {
  overflow: hidden;
  width: 24px;
  height: 24px;
}
.deleteButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  border-radius: 9999999px;
  flex-shrink: 0;
  overflow: hidden;
  width: 30px;
  height: 30px;
}
.deleteButton:hover {
  background-color: #d9d9d9;
}
.roleDefault {
  align-self: stretch;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 12px 24px 12px 12px;
  align-items: center;
  justify-content: space-between;
}
.roleDefault:hover {
  background-color: #f3f3f3;
}
.container,
.roles,
.rolesBox {
  align-self: stretch;
  flex-shrink: 0;
  align-items: flex-start;
}
.rolesBox {
  overflow-y: auto;
  flex: 1 1 auto;
}
.container,
.roles {
  box-sizing: border-box;
}
.roles {
  border-radius: 10px;
  border: 1px solid #bcbcbc;
  flex: 1 1 auto;
  margin-bottom: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 16px;
  color: #1b2027;
  font-family: Mulish;
}
.container {
  flex: 1 1 auto;
  padding: 0 16px;
  gap: 8px;
  height: 542;
  font-size: 16px;
  color: #4e4e4e;
}
.container,
.table,
.tableBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.tableBox {
  align-self: stretch;
  align-items: flex-start;
  font-size: 16px;
  color: #7c7d80;
}
.table {
  border-radius: 8px;
}
.table {
  align-self: stretch;
  overflow: hidden;
  align-items: center;
  gap: 12px;
  flex: 1 1 auto;
}
.tableBox {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
}
.addButton,
.group {
  align-self: stretch;
  display: flex;
}
.group {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  font-size: 14px;
  color: #707070;
  padding: 12px 16px;
}
.emailInput {
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid #a8a8aa;
  box-sizing: border-box;
  height: 38px;
  flex-direction: row;
  padding: 8px;
  font-size: 14px;
  width: 100%;
  justify-content: flex-start;
}
.addButton,
.addButtonContent {
  flex-direction: row;
  align-items: center;
}
.addButtonContent {
  display: flex;
  justify-content: flex-start;
  gap: 6px;
}
.addButton {
  cursor: pointer;
  border: 1.8px solid #973fd4;
  padding: 8px;
  background-color: #fff;
  border-radius: 4px;
  color: #973fd4;
  justify-content: center;
  height: 38px;
}
.addButton:hover {
  background-color: rgba(125, 57, 174, 0.17);
}
.addButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.add,
.addIcon {
  position: relative;
}
.addIcon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
  filter: brightness(0) saturate(100%) invert(33%) sepia(50%) saturate(2757%)
    hue-rotate(256deg) brightness(84%) contrast(97%);
}
.add {
  font-size: 14px;
  font-family: Mulish;
  text-align: right;
}
