.button {
  cursor: pointer;
  border: 0;
  padding: 8px 10px;
  background-color: #973fd4;
  align-self: stretch;
  border-radius: 4px;
  justify-content: center;
}
.button,
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content {
  justify-content: flex-start;
  gap: 8px;
}
.icon {
  position: relative;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  overflow: hidden;
}
.text {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-family: Mulish;
  color: #fff;
  text-align: left;
}
