.iconDownload {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  overflow: hidden;
}
.iconDownload,
.text {
  position: relative;
}
.buttonContainer,
.exportButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.exportButton {
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  background-color: #8852ad;
  border: 2px solid #7b4f9a;
  overflow: hidden;
  padding: 6px 8px;
  justify-content: flex-start;
  font-family: Mulish;
  gap: 6px;
}
.buttonContainer {
  position: relative;
  width: 100%;
  padding-bottom: 28px;
  box-sizing: border-box;
  justify-content: flex-end;
  text-align: left;
  font-size: 13px;
  color: #fff;
  font-family: Mulish;
}
