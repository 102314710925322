/* TODO: https://algorath.atlassian.net/browse/WMA-51  */

.root {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url(../../assets/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: 16px;
  color: #973fd4;
  font-family: Mulish;
}
.frameDiv {
  height: 100%;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
}
.frameDiv > * + * {
  margin-top: 24px;
}
