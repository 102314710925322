.closeIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.frameButton {
  cursor: pointer;
  border: 0;
  padding: 2px;
  background-color: transparent;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameButton:hover {
  background-color: #e0e0e0;
}
.descriptionDiv,
.textB {
  align-self: stretch;
  position: relative;
  font-family: Mulish;
  text-align: center;
  display: inline-block;
}
.textB {
  font-size: 24px;
  color: #25262b;
}
.descriptionDiv {
  font-size: 18px;
  font-weight: 500;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #515255;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.textDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.buttonsDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttonsDiv {
  align-self: stretch;
  gap: 32px;
}
.contentDiv,
.frameDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contentDiv {
  align-self: stretch;
  align-items: center;
  gap: 35px;
}
.frameDiv {
  flex: 1;
  align-items: flex-end;
}
.confirmationDialogForm {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 4px 28px 79px rgba(85, 37, 148, 0.1);
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 32px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
