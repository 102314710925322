.cellDeleted + .cellDeleted {
  border-left: 0;
}
.cellDeleted {
  flex: 1;
  border-color: #d6adad !important;
  border-left: solid;
  border-right: solid;
}
.cell + .cell {
  border-left: 0;
}
.cell {
  flex: 1;
  border-color: #e6e6e6 !important;
  border-left: solid;
  border-right: solid;
}
.invalidCell {
  background-color: #f2dede;
}
.bodyCellAutocomplete,
.bodyCellTextField {
  border-color: #e6e6e6 !important;
  flex: 1;
  align-self: stretch;
}
.bodyCellTextField {
  border: 0;
  background-color: transparent;
}
.rowDiv + .rowDiv {
  border-top: 0;
}
.rowDiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-color: #e6e6e6 !important;
  border-top: solid;
  border-bottom: solid;
  align-self: stretch;
  background-color: #fff;
  flex-direction: row;
}
.rowDivDeleted {
  color: #d6adad !important;
  background-color: #f2dede;
}
.rowTextDeleted {
  color: #d6adad;
}
