.chiptittleDiv {
  position: relative;
  font-weight: 500;
  display: inline-block;
}
.contentDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.vectorIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.iconclosechipButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  overflow: hidden;
}
.chipDiv {
  height: 24px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
