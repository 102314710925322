.textDiv {
  flex: 1;
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.headerCellDiv {
  height: 52px;
  display: flex;
  flex-direction: row;
  padding: 12px 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.header {
  height: 52px;
  align-self: stretch;
  border-radius: 8px 8px 0 0;
  background-color: #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #252525;
  font-family: Mulish;
  border-radius: 0px 8px 0 0;
}
