.headerTextB {
  flex: 1;
  position: relative;
  display: inline-block;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #515255;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.closeIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.closeButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.closeDiv,
.headerDiv {
  display: flex;
  flex-direction: column;
}
.closeDiv {
  align-self: stretch;
  padding: 0 0 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.headerDiv {
  padding: 0 16px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  box-sizing: border-box;
}
.auditDiv,
.entryContainerDiv {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: flex-start;
}
.entryContainerDiv {
  align-self: stretch;
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;
  padding: 0 16px;
  align-items: flex-start;
  font-size: 14px;
}
.auditDiv {
  position: relative;
  border-radius: 16px 0 0 0;
  background-color: #fff;
  box-shadow: -28px 28px 82px rgba(85, 37, 148, 0.1);
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 42px 24px 0;
  align-items: center;
  text-align: left;
  font-size: 24px;
  font-family: Mulish;
  padding-bottom: 60px;
}
