.createTeamTitle {
  margin-left: 5px;
  color: #973fd4;
}
.createTeamIconDiv {
  background-color: #973fd4;
}
.editTeamTitle {
  margin-left: 5px;
  color: #51a9a6;
}
.editTeamIconDiv {
  background-color: #51a9a6;
}
.inactivateTeamTitle {
  margin-left: 5px;
  color: #fb4e4e;
}
.inactivateTeamIconDiv {
  background-color: #fb4e4e;
}
.createTeamIconDiv,
.editTeamIconDiv,
.inactivateTeamIconDiv {
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100%;
}
.newRequestDiv1 {
  align-self: stretch;
  position: relative;
  font-weight: 600;
  display: flex;
  flex-direction: row;
}
.tittleDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.contentDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  flex-shrink: 0;
}
.contentDiv > * + * {
  margin-top: 26px;
}
.commentDiv {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.commentBoxTextarea,
.tittleDiv1 {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 4px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.commentBoxTextarea {
  font-family: Mulish;
  border: 0;
  resize: none;
  overflow: auto;
  background-color: #fff;
  align-self: stretch;
  border-radius: 8px;
  box-shadow: 4px 28px 79px rgba(85, 37, 148, 0.1);
  height: 107px;
  flex-shrink: 0;
  padding: 16px;
  align-items: flex-start;
}
.deleteIcon {
  position: relative;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  overflow: hidden;
}
.labelDiv {
  position: relative;
  font-size: 16px;
  font-family: Mulish;
  color: #222;
  text-align: left;
  display: inline-block;
}
.deleteButton:hover {
  font-weight: bold;
}
.deleteButton:hover .deleteIcon {
  stroke-width: 1.8;
}
.deleteButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.deleteButton > * + * {
  margin-left: 5px;
}
.contentDiv1 {
  font-family: Mulish;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  color: #252525;
}
.contentDiv1 > * + * {
  margin-top: 10px;
}
.buttonB,
.downloadIcon {
  position: relative;
}
.downloadIcon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
.navigateDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.buttonB {
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  font-family: Mulish;
  color: #fffffe;
  text-align: left;
}
.button {
  cursor: pointer;
  border: 0;
  padding: 12px 24px;
  background-color: #973fd4;
  border-radius: 9999px;
  box-shadow: 0 5px 6px rgba(103, 52, 168, 0.3);
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.button > * + * {
  margin-left: 8px;
}
.button:hover {
  background-color: #7d39ae;
}
.requestButtons {
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.requestButtons > * + * {
  margin-left: 16px;
}
.newRequestDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.newRequestDiv > * + * {
  margin-top: 48px;
}
.auditButtonSecondary {
  cursor: pointer;
  border: 2px solid #973fd4;
  padding: 12px 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 6px rgba(103, 52, 168, 0.3);
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.auditButtonSecondary > * + * {
  margin-left: 8px;
}
.auditB {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  font-family: Mulish;
  color: #973fd4;
  text-align: left;
}
.iconHistory {
  position: relative;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
.emailB,
.emailB1,
.statusDiv {
  position: relative;
  display: inline-block;
}
.emailB1 {
  color: #515255;
  font-size: 14px;
}
.reviewedByDiv {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #545454;
}
.dateB {
  font-size: 14px;
  align-self: stretch;
  position: relative;
  display: inline-block;
  color: #515255;
}
.requestDiv {
  align-self: stretch;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
}
.statusResolved,
.statusRejected,
.statusDeleted,
.statusSubmitted,
.statusCompleted {
  align-self: stretch;
  font-weight: bolder;
  font-size: 18px;
}
.statusResolved {
  color: #24a533;
}
.statusDeleted {
  color: #e55b57;
}
.statusRejected {
  color: #ff9533;
}
.statusSubmitted {
  color: #596573;
}
.statusCompleted {
  color: #497cff;
}
.requestInformationDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.requestInformationDiv > * + * {
  margin-top: 4px;
}
.upbarDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.containerDiv {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  align-self: stretch;
  padding: 0 70px 42px;
  font-size: 24px;
  color: #000;
}
.commentButton:hover {
  font-weight: bold;
  stroke-width: 1.8;
}
.commentButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.commentButton > * + * {
  margin-left: 5px;
}
.addIcon {
  position: relative;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  overflow: hidden;
  filter: brightness(0) saturate(100%) invert(32%) sepia(23%) saturate(6388%)
    hue-rotate(258deg) brightness(86%) contrast(92%);
}
.addLabelDiv {
  position: relative;
  font-size: 16px;
  font-family: Mulish;
  text-align: left;
  display: inline-block;
  color: #973fd4;
}
.deleteButton:hover .addIcon {
  stroke-width: 1.8;
}
.expiryDate {
  font-style: italic;
  font-size: 12px;
  font-family: Mulish;
  color: #222;
}
.addTicketDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.addTicketLabel {
  font-size: 16px;
  white-space: nowrap;
  font-weight: bold;
}
.addTicketInput {
  border-width: 1px;
  border-color: #252525;
  padding: 8px;
  background-color: transparent;
  font-size: 14px;
  font-family: Mulish;
  text-align: left;
  display: inline-block;
  width: 100%;
  margin-left: 8px;
  height: 17px;
  resize: none;
  outline: none;
}
