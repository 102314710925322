/* TODO: https://algorath.atlassian.net/browse/WMA-51  */

.icon,
.logoDiv {
  width: 54px;
  height: 54px;
  flex-shrink: 0;
}
.icon {
  position: relative;
}
.logoDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.appTittleDiv,
.descriptionDiv {
  position: relative;
  font-family: Mulish;
  color: #fff;
  text-align: center;
  display: inline-block;
}
.appTittleDiv {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 20px;
  font-weight: 800;
  width: 298px;
}
.descriptionDiv {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
}
.textContentDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.textContentDiv > * + * {
  margin-top: 8px;
}
.contentDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 0;
}
.contentDiv > * + * {
  margin-top: 12px;
}
.appButton {
  cursor: pointer;
  border: 0;
  padding: 42px;
  background-color: #2c81fb;
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 8px 18px rgba(53, 32, 97, 0.25);
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.appButton:hover {
  mix-blend-mode: normal;
  background-image: linear-gradient(rgba(0, 0, 0, 0.155) 0 0);
}
.appButton:hover .descriptionDiv {
  overflow: visible;
  width: 100%;
  white-space: normal;
}
.appButton:hover .appTittleDiv {
  overflow: visible;
  width: 100%;
  white-space: normal;
}
