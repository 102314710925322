.inputNumber {
  flex: 1;
  position: relative;
  border: none;
  resize: none;
  outline: none;
}
.cell {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #7c7d80;
  box-sizing: border-box;
  width: 150px;
  height: 28px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 2px 8px;
  align-items: center;
  justify-content: flex-start;
}
.dateSaved {
  position: relative;
  font-weight: 600;
}
.info {
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  text-align: left;
  font-size: 11px;
  color: #8d8d8d;
}
.component,
.expiryDate,
.info {
  display: flex;
  justify-content: flex-start;
}
.component {
  flex-direction: row;
  align-items: center;
  gap: 16px;
  text-align: right;
}
.expiryDate {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}
.saveText {
  position: relative;
}
.saveText {
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  font-family: Mulish;
  color: #fffffe;
  text-align: left;
}
.buttonAdd,
.content {
  display: flex;
  justify-content: flex-start;
}
.buttonAdd {
  cursor: pointer;
  border: 0;
  padding: 12px 24px 12px 21px;
  background-color: #5391cb;
  height: 42px;
  flex-shrink: 0;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  gap: 8px;
}
.buttonAdd:hover {
  background-color: #1f5e97;
}
.buttonAdd:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.content {
  padding: 12px 16px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  text-align: left;
  font-size: 14px;
  color: #3e3e3e;
  font-family: Mulish;
}
