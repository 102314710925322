.rowsDiv {
  background-color: #a8a8aa;
  border: 1px solid #a8a8aa;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}
.rowHeader {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  background-color: #a8a8aa;
  flex-direction: row;
  align-items: center;
  gap: 1px;
  text-align: left;
  font-size: 14px;
  font-family: Mulish;
}
.headerCellDiv {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  width: 159px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 8px 10px;
  box-sizing: border-box;
  align-items: center;
  background-color: #dfdfdf;
}
.headerTextB {
  flex: 1;
  position: relative;
  display: inline-block;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #515255;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.rowsDiv,
.contentCellDiv {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.contentCellDiv {
  background-color: #fff;
  flex: 1;
  flex-direction: row;
  padding: 8px 10px;
  box-sizing: border-box;
  align-items: center;
}
.textDiv {
  flex: 1;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #515255;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
