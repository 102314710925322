.newReviewer {
  flex: 1;
  position: relative;
}
.xIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.cross,
.tittle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.cross {
  cursor: pointer;
  border: 0;
  padding: 2px;
  background-color: transparent;
  border-radius: 9999px;
  align-items: flex-start;
}
.cross:hover {
  background-color: #bdbdbd;
}
.tittle {
  align-items: center;
}
.divider,
.header,
.tittle {
  align-self: stretch;
}
.divider {
  position: relative;
  border-top: 1px solid #a8a8aa;
  box-sizing: border-box;
  height: 1px;
  flex-shrink: 0;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.description {
  position: relative;
  font-weight: 500;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #515255;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.inputText {
  width: 100%;
}
.email,
.input,
.inputContainer {
  align-self: stretch;
  display: flex;
}
.email {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #a8a8aa;
  flex-direction: row;
  padding: 8px 12px;
  align-items: flex-start;
  justify-content: flex-start;
}
.input,
.inputContainer {
  flex-direction: column;
}
.input {
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}
.inputContainer {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.buttonCancelText {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-family: Mulish;
  color: #7c7d80;
  text-align: left;
}
.buttonCancel {
  cursor: pointer;
  border: 0;
  padding: 12px 24px;
  background-color: transparent;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.buttonCancel:hover {
  background-color: rgba(204, 204, 204, 0.6);
  border-radius: 24px;
}
.icon,
.buttonAddText {
  position: relative;
}
.icon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.buttonAddText {
  font-size: 16px;
  line-height: 20px;
  font-family: Mulish;
  color: #fffffe;
  text-align: left;
}
.buttonAdd,
.buttons {
  display: flex;
  flex-direction: row;
}
.buttonAdd {
  cursor: pointer;
  border: 0;
  padding: 12px 24px 12px 21px;
  background-color: #5391cb;
  border-radius: 9999px;
  box-shadow: 0 5px 6px rgba(103, 52, 168, 0.3);
  height: 42px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.buttonAdd:hover {
  background-color: #1f5e97;
}
.buttons {
  align-self: stretch;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 32px;
}
.content,
.popUp {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content {
  align-self: stretch;
  justify-content: flex-start;
  gap: 24px;
  font-size: 18px;
}
.popUp {
  position: relative;
  background-color: #e2e2e2;
  box-shadow: 0 4px 28px rgba(0, 0, 0, 0.1);
  border: 1px solid #a8a8a8;
  box-sizing: border-box;
  width: 100%;
  padding: 32px;
  justify-content: center;
  gap: 20px;
  text-align: left;
  font-size: 24px;
  color: #25262b;
  font-family: Mulish;
}
