.textPrimary,
.textTerciary,
.textSecondary,
.textGreen,
.textRed,
.textBlue,
.textTransparent {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  font-family: Mulish;
  text-align: left;
  font-weight: bold;
  align-items: center;
}
.textPrimary > * + * {
  margin-left: 8px;
}
.textSecondary > * + * {
  margin-left: 8px;
}
.textTerciary > * + * {
  margin-left: 8px;
}
.textGreen > * + * {
  margin-left: 8px;
}
.textRed > * + * {
  margin-left: 8px;
}
.textBlue > * + * {
  margin-left: 8px;
}
.textTransparent {
  margin-left: 8px;
}
.buttonPrimary,
.buttonTerciary,
.buttonSecondary,
.buttonGreen,
.buttonRed,
.buttonBlue,
.buttonTransparent {
  cursor: pointer;
  border: 0;
  padding: 12px 24px;
  position: relative;
  border-radius: 9999px;
  box-shadow: 0 5px 6px rgba(103, 52, 168, 0.3);
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.textSecondary {
  color: #973fd4;
}
.textPrimary,
.textTerciary,
.textGreen .textRed,
.textGreen,
.textRed,
.textBlue {
  color: #fffffe;
}
.textTransparent {
  color: #7c7d80;
}
.buttonPrimary {
  background-color: #973fd4;
}
.buttonPrimary:hover {
  background-color: #7d39ae;
}

.buttonSecondary {
  border: 2px solid #973fd4;
  background-color: #fff;
}
.buttonSecondary:hover {
  background-color: #f5eef7;
}

.buttonTerciary {
  background-color: #53cbc6;
}
.buttonTerciary:hover {
  background-color: #51a9a6;
}

.buttonGreen {
  background-color: #2ac769;
}
.buttonGreen:hover {
  background-color: #1da152;
}

.buttonRed {
  background-color: #fb4e4e;
}
.buttonRed:hover {
  background-color: #d93b3b;
}

.buttonBlue {
  background-color: #497cff;
}
.buttonBlue:hover {
  background-color: #2759d9;
}

.buttonTransparent {
  box-shadow: none;
  background-color: transparent;
}
.buttonTransparent:hover {
  background-color: rgba(224, 224, 224, 0.5);
}
